import styled from "styled-components";

const ShuttleSectionButtonWrapper = styled.button`
  border: none;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
  margin-top: 1.5rem;
  font-size: 16px;
  line-height: 22px;
  color: #${(props) => (props.disabled ? "a9abb2" : "FFF")};
  background-color: #${(props) => (props.disabled ? "eaecef" : props.error ? "FA253E" : "44D7B6")};
  cursor: pointer;
`;

export function ShuttleSectionButton({ title, disabled, onClick, error }) {
  return (
    <ShuttleSectionButtonWrapper
      onClick={onClick}
      disabled={disabled}
      error={error}
    >
      {title}
    </ShuttleSectionButtonWrapper>
  );
}
