import { NavLink } from "react-router-dom";
import styled from "styled-components";

const NotFound404Wrapper = styled.main`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #21061a;
`;

const NotImage = styled.img`
  width: 80vw;
`;

const HomeButton = styled.div`
  border: 1px solid #f7f8fa;
  padding: 0.3rem 5rem;
  cursor: pointer;
  background-color: #e7e7ef;
  color: #333;
  border-radius: 0.35rem;
  &:hover {
    border-color: rgba(68, 215, 182, 1);
    background-color: rgba(68, 215, 182, 1);
    color: #fff;
  }
`;

const NotFound404 = () => {
  return (
    <NotFound404Wrapper>
      <section>
        <span style={{ fontSize: "10rem", color: "#FFF", fontWeight: "bold" }}>
          4
        </span>
        <img src="./images/marsbb.png" style={{ width: "10rem" }} />
        <span style={{ fontSize: "10rem", color: "#FFF", fontWeight: "bold" }}>
          4
        </span>
      </section>
      <span
        style={{ color: "#FFF", width: 250, margin: 40, textAlign: "center" }}
      >
        Oh no! The page you were looking for can't be found here...
      </span>
      <NavLink to="/">
        <HomeButton>Go Home</HomeButton>
      </NavLink>
    </NotFound404Wrapper>
  );
};

export default NotFound404;
