import styled, { css } from "styled-components";

const SectionWrapper = styled.section`
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  @media (max-width: 600px) {
    min-height: 100vh;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;
const TextWrapper = styled.section`
  display: flex;
  flex-direction: column;
  color: #fff;
  flex: 1;
  padding-left: 8rem;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 33;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 1rem;
    width: 100%;
    height 100%;
  }
`;
const MarsImg = styled.img`
  width: 40rem;
  @media (max-width: 600px) {
    width: 35rem;
    position: fixed;
    bottom: 0;
  }
`;

const ToMarsImg = styled.img`
  width: 32rem;
  transition: all 1s;
  position: fixed;
  z-index: 0;
  bottom: 1%;
  right: 25%;
  @media (max-width: 600px) {
    right: 0%;
    bottom: 0;
    width: 22rem;
  }
`;

const TableSpan = styled.span`
  color: #8e8bb0;
  @media (max-width: 600px) {
    color: #fff;
  }
`;

const TableSpanT = styled(TableSpan)`
  font-weight: bold;
  font-size: 0.9rem;
`;

const TableSpanC = styled(TableSpan)`
  font-size: 0.8rem;
`;

const PlayNowButton = styled.button`
  border: none;
  border-bottom: #e46035 solid 2px;
  background: transparent;
  color: #fff;
  padding: 0.6rem 0;
  cursor: pointer;
  margin-top: 2rem;
  font-weight: bold;
  @media (max-width: 600px) {
    background: #e46035;
    border-radius: 0.4rem;
    padding: 0.4rem 0.4rem;
  }
`;
export default function MarsSection() {
  return (
    <SectionWrapper>
      <TextWrapper>
        <span>ABOUT</span>
        <span style={{ fontSize: "4rem", fontWeight: "bold" }}>Mars Home</span>
        <span style={{ fontSize: "2rem", fontWeight: "bold" }}>
          - HelloDao is a metaverse game that aims to create a digital Martian city.
        </span>
        <span style={{ maxWidth: 390, marginTop: "3rem" }}>
          The game will simulate and present the process of human landing on Mars through multiple perspectives such as landing, construction, immigration, and war.
        </span>
        <section
          style={{
            display: "grid",
            maxWidth: 390,
            height: 100,
            gridTemplateColumns: "repeat(3, 130px)",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              height: 60,
              justifyContent: "space-between",
            }}
          >
            <TableSpanT>Volume</TableSpanT>
            <TableSpanC>
              1.6318x10<sup>12</sup> km<sup>3</sup>
            </TableSpanC>
          </section>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              height: 60,
              justifyContent: "space-between",
            }}
          >
            <TableSpanT>Mass</TableSpanT>
            <TableSpanC>
              6.4171x10<sup>24</sup> kg
            </TableSpanC>
          </section>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              height: 60,
              justifyContent: "space-between",
            }}
          >
            <TableSpanT>Gravity</TableSpanT>
            <TableSpanC>
              3.711 m/s<sup>2</sup>
            </TableSpanC>
          </section>
        </section>

        {/* <PlayNowButton>
          <a style={{ color: '#FFF' }} href="https://dapp.hellodao.world/">Main Net</a>
        </PlayNowButton> */}
        <PlayNowButton>
          <a style={{ color: '#FFF' }} href="https://testnet.hellodao.world/">Test Net</a>
        </PlayNowButton>
      </TextWrapper>
      <section style={{ flex: 1.5 }}>
        <MarsImg src="./images/marsbb.png" />
      </section>
      <ToMarsImg src="./assets/icon/gomars.png" />
    </SectionWrapper>
  );
}
