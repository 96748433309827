import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";
import ShuttleHeader from "../../components/ShuttleHeader";
import ShuttleSection from "../../components/ShuttleSection";

const ShuttleWrapper = styled.section`
  background-image: url(./assets/svg/shuttle_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color-burn;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-flow: column;
  background-color: var(--shuttle-background-color);
`;


const Shuttle = () => {
  return (
    <ShuttleWrapper>
        <ShuttleHeader />
        <ShuttleSection />
        <ToastContainer />
      </ShuttleWrapper>
  );
};

export default Shuttle;
