import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { initWeb3 } from "../../constants/contract";
import { toast } from "react-toastify";
import { AddressContext } from "../../pages/App";
import { BscWithdrawalDao, EthWithdrawalDao, SwitchChain } from "../../constants/methods";

const ShuttleRecordItemWrapper = styled.section`
  max-width: 1000px;
  width: 90%;
  background-color: var(--shuttle-section-coin-background-color);;
  padding: 0 0.6rem;
  display: grid;
  height: 2.4rem;
  overflow: hidden;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  align-items: center;
  box-shadow: 1px 1px 1px 1px #89b5dc;
  font-size: 14px;
  margin: 0.5rem 0;
  user-select: none;
  border-radius: 0.3rem;
  @media (max-width: 700px) {
    grid-gap: 0.1rem;
  }
`;

const Hash = styled.a`
  color: #00c98f;
  cursor: pointer;
`;

const AddressWrapper = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AddressIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin: 0 0.2rem;
`;

const Address = styled.a`
  color: #00c98f;
`;

const Status = styled.span`
  background-color: var(--shuttle-section-coin-background-color);
  padding: .2rem ${props => props.small ? ".2" : ".5"}rem;
  border-radius: 999px;
  font-size: ${props => props.small ? ".4" : ".8"}rem;
  color: #${(props) => (props.status === 1 ? "fe8f22" : props.status === 2 ? "04C98F" : "666")};
`;

const ReceiveButton = styled.div`
  background-color: #89b5dc;
  font-size: ${props => props.small ? ".6" : ".8"}rem;
  padding: 0.2rem ${props => props.small ? ".4" : ".7"}rem;
  color: #fff;
  border-radius: 0.3rem;
  font-weight: bold;
  cursor: pointer;
`;

const ShuttleRecordItem = ({
  data: { tx, account, amount, status, from, to, orderId, proof },
}) => {
  const { address } = useContext(AddressContext);
  const [smallWindow, setSmallWindow] = useState(window.innerWidth <= 700 ?? false);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setSmallWindow(e.target.innerWidth <= 700);
    });
  }, []);

  const onWithdrawalDao = () => {
    if (from === "eth") {
       BscWithdrawalDao(address, orderId, amount, proof);
    } else {
      EthWithdrawalDao(address, orderId, amount, proof);
    }
  };

  const switchChain = () => {
    SwitchChain(from === "eth" ? "bsc" : "eth").then(() => onWithdrawalDao()).catch(() => toast.error("切换链失败"))
  };

  return (
    <ShuttleRecordItemWrapper>
      <Hash
        href={String(
          from === "eth"
            ? process.env.REACT_APP_ETH_SCAN_TX_URL
            : process.env.REACT_APP_BSC_SCAN_TX_URL
        ).replace("hash", tx)}
        target="_blank"
      >
        {smallWindow ? "#" : String(tx).substring(0, 4)+"..."}
        {String(tx).substring(String(tx).length - 4)}
      </Hash>
      <AddressWrapper>
        <AddressIcon src={`./assets/icon/${from}.png`} />
        <Address
          href={String(
            from === "eth"
              ? process.env.REACT_APP_ETH_SCAN_ADDRESS_URL
              : process.env.REACT_APP_BSC_SCAN_ADDRESS_URL
          ).replace("hash", account)}
          target="_blank"
        >
          {smallWindow ? "#" : String(account).substring(0, 4)+"..."}
          {String(account).substring(String(account).length - 4)}
        </Address>
      </AddressWrapper>
      <AddressWrapper>
        <AddressIcon src={`./assets/icon/${to}.png`} />
        <Address
          href={String(
            to === "eth"
              ? process.env.REACT_APP_ETH_SCAN_ADDRESS_URL
              : process.env.REACT_APP_BSC_SCAN_ADDRESS_URL
          ).replace("hash", account)}
          target="_blank"
        >
          {smallWindow ? "#" : String(account).substring(0, 4)+"..."}
          {String(account).substring(String(account).length - 4)}
        </Address>
      </AddressWrapper>
      <Status status={status} small={smallWindow}>
        {status === 1 ? smallWindow  ? "⚈" :"跨链中" : status === 2 ? smallWindow  ? "⚈" :"已提取" : "已撤回"}
      </Status>
      <AddressWrapper>
        <AddressIcon src="./assets/icon/dao.png" />
        <Address
          href={String(
            from === "eth"
              ? process.env.REACT_APP_ETH_DAO_ADDRESS
              : process.env.REACT_APP_BSC_DAO_ADDRESS
          ).replace("hash", account)}
          target="_blank"
        >
          {smallWindow ? "" : "DAO"}
        </Address>
      </AddressWrapper>
      <span style={{fontSize: smallWindow ? ".6rem" : ".9rem", color: "var(--shuttle-section-input-color)"}}>
        {Number(initWeb3().utils.fromWei(String(amount), "ether")).toFixed(2)}
      </span>
      {status === 1 &&
      String(address).toLowerCase() === String(account).toLowerCase() ? (
        <ReceiveButton  small={smallWindow} onClick={switchChain}>接收</ReceiveButton>
      ) : (
        <span></span>
      )}
    </ShuttleRecordItemWrapper>
  );
};

export default ShuttleRecordItem;
