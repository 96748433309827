import { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Space } from "../../Golbal";
import { ConnectButton } from "../ConnectButton";
import SmallButton from "../SmallButton";

const ShuttleHeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => props.height ?? 14}rem;
  width: 90%;
  max-width: ${(props) => props.width ?? 40}rem;
  @media (max-width: 550px) {
    width: 90%;
    height: 4rem;
    padding: 0 1rem;
  }
`;

const ShuttleHeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShuttleLogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ShuttleLogo = styled.img`
  width: 2rem;
  height: 2rem;
  @media (max-width: 550px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
const ShuttleTitle = styled.span`
  font-size: 30px;
  margin-left: 1rem;
  font-weight: bold;
  color: rgba(68, 215, 182, 1);
  @media (max-width: 550px) {
    font-size: 20px;
  }
`;

const ButtonS = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 550px) {
    position: fixed;
    bottom: 40px;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ShuttleHeader = ({ width, height, home = false }) => {
  const rootEle = document.getElementById("root");
  const [isDark, setIsDark] = useState("")
  return (
    <ShuttleHeaderWrapper width={width} height={height}>
      <ShuttleLogoWrapper>
        <ShuttleLogo src="./assets/icon/shuttle.png" />
        <ShuttleTitle>Shuttle DAO</ShuttleTitle>
      </ShuttleLogoWrapper>

      <ShuttleHeaderItem>
        <ConnectButton />
        <Space />
        <ButtonS>
          {home ? (
            <NavLink to="/shuttle">
              <SmallButton icon={"./assets/icon/home.png"} />
            </NavLink>
          ) : (
            <NavLink to="record">
              <SmallButton icon={"./assets/icon/table.png"} />
            </NavLink>
          )}
          <Space />
          <SmallButton
            icon={
              isDark === "dark"
                ? "./assets/icon/moon.png"
                : "./assets/icon/sun.png"
            }
            onClick={() => {
              rootEle.className = rootEle.className === "dark" ? "" : "dark";
              setIsDark(rootEle.className)
            }}
          />
          <Space />
          <SmallButton title={"文"} />
        </ButtonS>
      </ShuttleHeaderItem>
    </ShuttleHeaderWrapper>
  );
};

export default ShuttleHeader;
