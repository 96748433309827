import styled from "styled-components";
import { useContext } from "react";
import { AddressContext } from "../../pages/App";
import { onConnect } from "../../constants/methods";

const ConnectButtonWrapper = styled.div`
  color: var(--connect-button-color);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border: var(--connect-button-color) solid 1px;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 0.9rem;
  @media(max-width: 550px) {
    font-size: 0.7rem;
    height: 1.5rem;
  }
`;

export function ConnectButton() {
  const { address, dispatch } = useContext(AddressContext);
  return (
    <ConnectButtonWrapper onClick={() => onConnect(address, dispatch)}>
      <span>
        {address
          ? `${address.substring(0, 5)}***${address.substring(
              address.length - 5
            )}`
          : "连接钱包"}
      </span>
    </ConnectButtonWrapper>
  );
}
