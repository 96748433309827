import {
  createContext,
  useReducer,
  useState,
  useEffect,
  Fragment,
} from "react";
import styled from "styled-components";
import ShuttleHeader from "../../components/ShuttleHeader";
import ShuttleRecordItem from "../../components/ShuttleRecordItem";
import ShuttleRecordItemHeader from "../../components/ShuttleRecordItemHeader";

const ShuttleRecordWrapper = styled.section`
  background-image: url(./assets/svg/shuttle_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color-burn;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-flow: column;
  background-color: var(--shuttle-background-color);
`;

const PageWrapper = styled.section`
  display: flex;
  align-items: center;
  margin-top: 2rem;
`;

const PageButton = styled.button`
  background: none;
  border: none;
  padding: none;
  cursor: pointer;
`;

const PageIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;

const PageText = styled.span`
  margin: 0 0.4rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--shuttle-section-input-color);
`;

const ShuttleRecord = () => {
  const [recordData, setRecordData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getRecordData();
  }, []);

  useEffect(() => {
    if (recordData.length > 0) {
      setPageData(
        recordData.slice(
          currentPage * 10,
          recordData.length > 10 + currentPage * 10
            ? 10 + currentPage * 10
            : recordData.length
        )
      );
    }
  }, [currentPage]);

  const getRecordData = async () => {
    const ethDeposit = await fetch(
      "https://api.hellodao.world/cross/eth_deposit.json"
    ).then((res) => res.json());
    console.log(ethDeposit);
    const bscDeposit = await fetch(
      "https://api.hellodao.world/cross/bsc_deposit.json"
    ).then((res) => res.json());
    const newEthDeposit = ethDeposit?.data?.map((data) => ({
      ...data,
      root: ethDeposit.root,
      from: "eth",
      to: "bsc",
    })) ?? [];
    const newBscDeposit = bscDeposit?.data?.map((data) => ({
      ...data,
      root: bscDeposit.proof,
      from: "bsc",
      to: "eth",
    })) ?? [];
    const list = [...newEthDeposit, ...newBscDeposit];
    setRecordData(list);
    setPageData(list.slice(0, list.length > 10 ? 10 : list.length));
  };

  return (
    <ShuttleRecordWrapper>
      <ShuttleHeader width={'60'} height={10} home/>
      <ShuttleRecordItemHeader />
      {pageData.map((data) => {
        return <ShuttleRecordItem key={data.tx} data={data} />;
      })}
      <PageWrapper>
        <PageButton
          disabled={currentPage <= 0}
          onClick={() => setCurrentPage(currentPage <= 0 ? 0 : currentPage - 1)}
        >
          <PageIcon src="./assets/icon/last.png" />
        </PageButton>
        <PageText>{currentPage + 1}</PageText>
        <PageText>/</PageText>
        <PageText>{Math.ceil(recordData.length / 10)}</PageText>
        <PageButton
          disabled={currentPage >= Math.ceil(recordData.length / 10) - 1}
          onClick={() =>
            setCurrentPage(
              currentPage >= Math.ceil(recordData.length / 10) - 1
                ? currentPage
                : currentPage + 1
            )
          }
        >
          <PageIcon src="./assets/icon/next.png" />
        </PageButton>
      </PageWrapper>
    </ShuttleRecordWrapper>
  );
};

export default ShuttleRecord;
