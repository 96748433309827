import { useEffect, useRef } from "react";
import styled from "styled-components";

const ShuttleSectionItemWrapper = styled.section`
  width: 100%;
  display: flex;
`;

const ShuttleSectionItemCoin = styled.section`
  padding: 0.75rem;
  background-color: var(--shuttle-section-coin-background-color);
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const ShuttleSectionItemCoinIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
`;
const ShuttleSectionItemCoinName = styled.span`
  color: #3d3f4c;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.25rem;
  color: var(--shuttle-section-input-color);
`;

const ShuttleSectionItemInputWrapper = styled.section`
  width: 100%;
  border: var(--shuttle-section-coin-background-color) 1px solid;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const ShuttleSectionItemInputWrapperItem = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ShuttleSectionItemInputWrapperFlex = styled.section`
  display: flex;
  align-items: center;
`;

const ShuttleSectionItemInputWrapperIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const ShuttleSectionItemInputWrapperCoinName = styled(
  ShuttleSectionItemCoinName
)`
  font-size: 11px;
  margin-top: 0;
  margin-left: 5px;
`;

const ShuttleSectionItemInputWrapperInput = styled.input`
  outline: 2px solid transparent;
  outline-offset: 2px;
  background: transparent;
  color: ${(props) => (props.disabled ? "#999" : props.err ? "#E8221F" : "var(--shuttle-section-input-color)")};
  border: none;
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 500;
  flex: 1;
  width: 100%;
`;

const ShuttleSectionItemInputWrapperMaxButton = styled.button`
  color: rgba(68, 215, 182, 1);
  font-size: 13px;
  line-height: 14px;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: var(--shuttle-section-coin-background-color);
  border: transparent 1px solid;
  cursor: pointer;
  &:hover {
    border-color: rgba(68, 215, 182, 1);
  }
`;

const ErrorNotice = styled.span`
  font-size: 11px;
  color: red;
  margin: 0 0.5rem;
  display: ${(props) => (props.vis ? "inline-block" : "none")};
`;

const ShuttleSectionItem = ({
  setShuttleValue,
  chainPng,
  chainName,
  coinPng,
  coinName,
  inputDisabled = false,
  balance = 0,
  inputValue = 0,
}) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef != null) inputRef.current.value = inputValue;
  }, [inputValue]);
  useEffect(() => {
    if (balance === 0 && inputRef != null) inputRef.current.value = balance;
  }, [balance]);
  return (
    <ShuttleSectionItemWrapper>
      <ShuttleSectionItemCoin>
        <ShuttleSectionItemCoinIcon src={chainPng} />
        <ShuttleSectionItemCoinName>{chainName}</ShuttleSectionItemCoinName>
      </ShuttleSectionItemCoin>
      <ShuttleSectionItemInputWrapper>
        <ShuttleSectionItemInputWrapperItem>
          <ShuttleSectionItemInputWrapperFlex>
            <ShuttleSectionItemInputWrapperIcon src={coinPng} />
            <ShuttleSectionItemInputWrapperCoinName>
              {coinName}
            </ShuttleSectionItemInputWrapperCoinName>
            <ErrorNotice
              vis={
                !inputDisabled &&
                Number(inputRef?.current?.value ?? 0) > Number(balance)
              }
            >
              余额不足
            </ErrorNotice>
          </ShuttleSectionItemInputWrapperFlex>
          <ShuttleSectionItemInputWrapperCoinName>
            余额: {balance}
          </ShuttleSectionItemInputWrapperCoinName>
        </ShuttleSectionItemInputWrapperItem>
        <ShuttleSectionItemInputWrapperItem>
          <ShuttleSectionItemInputWrapperInput
            placeholder="0.0"
            ref={inputRef}
            disabled={inputDisabled}
            err={
              !inputDisabled &&
              Number(inputRef?.current?.value ?? 0) > Number(balance)
            }
            onChange={() => setShuttleValue(inputRef.current.value)}
          />
          <ShuttleSectionItemInputWrapperMaxButton
            onClick={() => {
              inputRef.current.value = balance;
              setShuttleValue(inputRef.current.value);
            }}
          >
            最大
          </ShuttleSectionItemInputWrapperMaxButton>
        </ShuttleSectionItemInputWrapperItem>
      </ShuttleSectionItemInputWrapper>
    </ShuttleSectionItemWrapper>
  );
};

export default ShuttleSectionItem;
