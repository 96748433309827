import {
  DaoEthContract,
  DaoBscContract,
  BridgeEthContract,
  initWeb3,
  BridgeBscContract,
  Ethereum,
} from "./contract";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { soliditySha3 } from "web3-utils/src/soliditySha3";
import { UPDATE_ADDRESS } from "../pages/App";

export const onConnect = async (address, dispatch) => {
  if (address !== "") return;
  if (window.ethereum) {
    let accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    dispatch({ type: UPDATE_ADDRESS, address: accounts[0] ?? "" });
  }
};

export const DaoEthBalance = async (address) => {
  const balance = await DaoEthContract(address, true)
    .methods.balanceOf(address)
    .call()
    .catch((error) => {
      throw `${error.toString()}`;
    });
  return balance;
};

export const DaoBSCBalance = async (address) => {
  const balance = await DaoBscContract(address, true)
    .methods.balanceOf(address)
    .call()
    .catch((error) => {
      throw `${error.toString()}`;
    });
  return balance;
};

export const EthDaoAllowance = async (address) => {
  const allowance = await DaoEthContract(address, true)
    .methods.allowance(address, process.env.REACT_APP_ETH_BRIDGE_ADDRESS)
    .call()
    .catch((error) => {
      throw `${error.toString()}`;
    });
  return (allowance ?? 0) > 0;
};

export const BscDaoAllowance = async (address) => {
  const allowance = await DaoBscContract(address, true)
    .methods.allowance(address, process.env.REACT_APP_BSC_BRIDGE_ADDRESS)
    .call()
    .catch((error) => {
      throw `${error.toString()}`;
    });
  return (allowance ?? 0) > 0;
};

export const EthDaoApproval = async (address) => {
  console.log('EthDaoApproval')
  const allowance = await DaoEthContract(address)
    .methods.approve(
      process.env.REACT_APP_ETH_BRIDGE_ADDRESS,
      initWeb3().utils.toWei("100000000000", "ether")
    )
    .send({ from: address })
    .catch((error) => {
      console.error(error)
      throw `${error.toString()}`;
    });
  return allowance;
};

export const BscDaoApproval = async (address) => {
  const allowance = await DaoEthContract(address)
    .methods.approve(
      process.env.REACT_APP_BSC_BRIDGE_ADDRESS,
      initWeb3().utils.toWei("100000000000", "ether")
    )
    .send({ from: address })
    .catch((error) => {
      console.log(error)
      throw `${error.toString()}`;
    });
  return allowance;
};

export const EthDepositDao = async (address, amount) => {
  console.log("address", address)
  const dao = await BridgeEthContract(address)
    .methods.depositDao(initWeb3().utils.toWei(amount, "ether"))
    .send({ from: address })
    .catch((error) => {
      console.error(error)
      throw `${error.toString()}`;
    });
  return dao;
};

export const BSCDepositDao = async (address, amount) => {
  const dao = await BridgeBscContract(address)
    .methods.depositDao(initWeb3().utils.toWei(amount, "ether"))
    .send({ from: address })
    .catch((error) => {
      throw `${error.toString()}`;
    });
  return dao;
};

export const EthBridgeMerkleRoot = async (address) => {
  const proof = await BridgeEthContract(address, true)
    .methods.merkleRoot()
    .call()
    .catch((error) => {
      throw `${error.toString()}`;
    });
  return proof;
};

export const BscBridgeMerkleRoot = async (address) => {
  const proof = await BridgeBscContract(address, true)
    .methods.merkleRoot()
    .call()
    .catch((error) => {
      throw `${error.toString()}`;
    });
  return proof;
};

export const BscWithdrawalDao = (address, orderId, amount, proof) => {
  BridgeBscContract(address)
    .methods.withdrawalDao(orderId, String(amount), proof)
    .send({ from: address })
    .catch((error) => {
      throw `${error.toString()}`;
    });
};

export const EthWithdrawalDao = async (address, orderId, amount, proof) => {
  await BridgeEthContract(address)
    .methods.withdrawalDao(orderId, String(amount), proof)
    .send({ from: address })
    .catch((error) => {
      throw `${error.toString()}`;
    });
};

export const merkleProof = (address, list = []) => {
  const leafNodes = list.map((addr) => {
    let arr = addr.split(",");
    return soliditySha3(arr[0], arr[1], arr[2]);
  });

  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
  const rootHash = merkleTree.getRoot();
  // console.log("rootHash", rootHash.toString("hex"));
  const merkleProof = merkleTree.getHexProof(leafNodes[0]);
  // console.log(merkleProof);
  return merkleProof;
};

export const checkNetError = (ethOrBsc, chainId) => {
  const bool =
    ethOrBsc === "eth"
      ? chainId !== process.env.REACT_APP_ETH_CHAIN_ID
      : chainId !== process.env.REACT_APP_BSC_CHAIN_ID;
  return bool;
};

export const SwitchChain = (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      await Ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId:
              type === "eth"
                ? process.env.REACT_APP_ETH_CHAIN_ID
                : process.env.REACT_APP_BSC_CHAIN_ID,
          },
        ],
      });
      resolve(1);
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await Ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId:
                  type === "eth"
                    ? process.env.REACT_APP_ETH_CHAIN_ID
                    : process.env.REACT_APP_BSC_CHAIN_ID,
                chainName:
                  type === "eth"
                    ? process.env.REACT_APP_ETH_CHAIN_NAME
                    : process.env.REACT_APP_BSC_CHAIN_NAME,
                rpcUrls:
                  type === "eth"
                    ? [process.env.REACT_APP_ETH_RPC_URL]
                    : [process.env.REACT_APP_BSC_RPC_URL],
              },
            ],
          });
          resolve(1);
        } catch (addError) {
          reject(addError);
        }
      } else {
        reject(switchError)
      }
    }
  });
};
