import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import NotFound404 from "./NotFound404";
import Shuttle from "./Shuttle";
import styled from "styled-components";
import ShuttleRecord from "./ShuttleRecord";
import { createContext, useReducer, useEffect, useState } from "react";
import { Ethereum } from "../constants/contract";

const AppWrapper = styled.main`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`;

export const AddressContext = createContext({});
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ADDRESS:
      return action.address;
    default:
      return state;
  }
};

function App() {
  const [address, dispatch] = useReducer(reducer, "");
  useEffect(() => {
    if (address !== "") {
      Ethereum.on("accountsChanged", accounts => {
        dispatch({ type: UPDATE_ADDRESS, address: accounts[0] ?? "" });
      })
      Ethereum.on("chainChanged", chainId => {
        // console.log(chainId)
        // window.location.reload()
      })
    }
  }, [address]);
  return (
    <AddressContext.Provider value={{ address, dispatch }}>
      <AppWrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="shuttle" element={<Shuttle />} />
          <Route path="shuttle/record" element={<ShuttleRecord />} />
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </AppWrapper>
    </AddressContext.Provider>
  );
}

export default App;
