import styled from "styled-components";

const SmallButtonWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  background-color: #f0f1f8;
  font-size: 0.9rem;
  color: #3d3f4c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  @media (max-width: 550px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const SmallButtonIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const SmallButton = ({ title = "", icon = null, onClick = null }) => {
  return (
    <SmallButtonWrapper onClick={onClick}>
      {icon ? <SmallButtonIcon src={icon} /> : title}
    </SmallButtonWrapper>
  );
};

export default SmallButton;
