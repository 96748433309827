import styled from "styled-components";
const SectionWrapper = styled.section`
  width: 90%;
  height: 80vh;
  @media (max-width: 600px) {
    min-height: 100vh;
    height: 100%;
    margin-bottom: 20vh;
  }
`;
export default function RoadMapSection() {
  const datas = [
    {
      title: "2021 Q4",
      color: "#727BF7",
      item: [
        "Project initiator Roy began to form a team",
        "planning project",
      ],
    },
    {
      title: "2022 Q1",
      color: "#0C092F",
      item: [
        "Create an early community",
        "Create official website",
        "Complete the hero NFT design",
        "Develop and test game contracts",
      ],
    },
    {
      title: "2022 Q2",
      color: "#09124F",
      item: [
        "Free Mint Plan",
        "Launching hero NFTs on the BSC mainnet",
        "Let 20,000+ community members own NFT for free",
        "Start NFT cross-chain bridge",
        "Start H5 client development",
      ],
    },
    {
      title: "2022 Q3",
      color: "#1B6295",
      item: [
        "The game is launched on the BSC mainnet",
        "IDO launch",
        "START HELLO FARM",
      ],
    },
    {
      title: "2022 Q4",
      color: "#04E06D",
      item: [
        "open mars city",
      ],
    },
    {
      title: "2023",
      color: "#E30C6D",
      item: [
        "Start the Starlink Plan",
      ],
    },
  ];
  return (
    <SectionWrapper>
      <span
        style={{
          display: "block",
          fontSize: "3rem",
          fontWeight: "bold",
          lineHeight: "7rem",
          textAlign: "center",
          width: "100%",
        }}
      >
        RoadMap
      </span>
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {datas.map((data, index) => {
          return (
            <section
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "18rem",
                margin: "1rem",
              }}
            >
              <section
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: data.color,
                  padding: ".3rem",
                  borderRadius: ".3rem",
                }}
              >
                <img
                  src={`./assets/icon/menu.png`}
                  alt="1"
                  style={{ width: "1.5rem" }}
                />
                <span style={{ fontWeight: "bold", marginLeft: ".5rem" }}>
                  {data.title}
                </span>
              </section>

              <section
                style={{
                  background: "#FFF",
                  borderRadius: ".3rem",
                  padding: ".5rem",
                  marginTop: "1rem",
                }}
              >
                {data.item.map((content, index) => {
                  return (
                    <span
                      key={index}
                      style={{
                        fontSize: ".9rem",
                        lineHeight: "1.3rem",
                        color: "#333",
                        display: "block",
                        lineHeight: "1.5rem",
                        fontWeight: "500",
                      }}
                    >
                      {index + 1}.{"  "}
                      {content}
                    </span>
                  );
                })}
              </section>
            </section>
          );
        })}
      </section>
    </SectionWrapper>
  );
}
