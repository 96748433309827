import { useEffect, useContext, useState } from "react";
import { DaoBscContract, DaoEthContract, getChainId, initWeb3 } from "../../constants/contract";
import { toast } from "react-toastify";
import styled from "styled-components";
import { AddressContext } from "../../pages/App";
import {
  DaoEthBalance,
  DaoBSCBalance,
  EthDepositDao,
  EthDaoAllowance,
  EthDaoApproval,
  BSCDepositDao,
  BscDaoAllowance,
  BscDaoApproval,
  SwitchChain,
  onConnect,
  checkNetError,
} from "../../constants/methods";
import { ShuttleSectionButton } from "./ShuttleSectionButton";
import ShuttleSectionItem from "./ShuttleSectionItem";
import Web3 from "web3";
import DaoApi from "../../constants/abis/dao.json";

const ShuttleSectionWrapper = styled.section`
  width: 27.5rem;
  padding: 1.5rem;
  border-radius: 1.25rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: var(--shuttle-section-background-color);
  box-shadow: 0 0 10px var(--shuttle-section-box-shadow-color);
  @media (max-width: 550px) {
    width: 80%;
    margin-top: 10rem;
  }
`;

const ExchangeButton = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
`;

const ExchangeIcon = styled.img`
  width: 1.2rem;
  height: 1.2rem;
`;

const StaticData = {
  eth: {
    chainImage: "./assets/icon/eth.png",
    chainName: "ETH",
    coinImage: "./assets/icon/dao.png",
    coinName: "DAO",
  },
  bsc: {
    chainImage: "./assets/icon/bsc.png",
    chainName: "BSC",
    coinImage: "./assets/icon/dao.png",
    coinName: "DAO",
  },
};

const ShuttleSection = () => {
  const { address, dispatch } = useContext(AddressContext);
  const [ethDaoBalance, setEthDaoBalance] = useState(0);
  const [bscDaoBalance, setBscDaoBalance] = useState(0);
  const [shuttleValue, setShuttleValue] = useState(0);
  const [ethOrBsc, setEthOrBsc] = useState("eth");
  const [daoAllowance, setDaoAllowance] = useState(false);
  const [buttonTitle, setButtonTitle] = useState("授权");
  const [isShuttle, setIsShuttle] = useState(false);
  const [netError, setNetError] = useState("0");

  useEffect(() => {
    if (address) {
      updateDaoBalance();
      getEthDaoAllowance();
    }
  }, [address]);

  const updateDaoBalance = async () => {
    getEthBalance();
    getBscBalance();
    const chainId = await getChainId();
    setNetError(checkNetError(ethOrBsc, chainId));
  };

  const getEthBalance = async () => {
    const balance = await DaoEthBalance(address);
    console.log("getEthBalance", balance)
    setEthDaoBalance(
      Number(initWeb3().utils.fromWei(balance ?? 0, "ether")).toFixed(4)
    );
  };

  const getBscBalance = async () => {
    const balance = await DaoBSCBalance(address);
    console.log("getBscBalance", balance)
    setBscDaoBalance(
      Number(initWeb3().utils.fromWei(balance ?? 0, "ether")).toFixed(4)
    );
  };

  const getEthDaoAllowance = async () => {
    const allowance = await (ethOrBsc === "eth"
      ? EthDaoAllowance(address)
      : BscDaoAllowance(address));
    setDaoAllowance(allowance ?? false);
    setButtonTitle(allowance ? "跨链转出" : "授权");
    return allowance
  };

  const onDaoApproval = async () => {
    const allowance = await (ethOrBsc === "eth"
      ? EthDaoApproval(address)
      : BscDaoApproval(address)
    ).catch(() => toast.error("授权失败"));
    setDaoAllowance(allowance ?? false);
    setButtonTitle(allowance ? "跨链转出" : "授权");
  };

  const onDepositDao = async () => {
    const allowance = await getEthDaoAllowance()
    if(!allowance) {
      toast.info("请先授权跨链操作")
      onDaoApproval()
      return
    }
    setIsShuttle(true);
    toast.promise(
      new Promise(async (resolve, reject) => {
        const daoInfo = await (ethOrBsc === "eth"
          ? EthDepositDao(address, shuttleValue)
          : BSCDepositDao(address, shuttleValue)
        ).catch(() => reject(null));
        const orderId = daoInfo?.events?.BridgeEvent?.returnValues?.orderId;
        setIsShuttle(false);
        updateDaoBalance();
        setShuttleValue(0);
        if (Number(orderId ?? 0) > 0) {
          resolve(null);
        } else {
          reject(null);
        }
      }),
      {
        pending: "正在执行跨链操作",
        success: "跨链成功,稍后在跨链记录中接收",
        error: "跨链失败",
      }
    );
  };

  const switchChain = (ethOrBsc, fn) => {
    SwitchChain(ethOrBsc)
      .then(async () => {
        toast.success("切换成功");
        const chainId = await getChainId();
        setNetError(checkNetError(ethOrBsc, chainId));
        if (typeof fn === "function") fn();
      })
      .catch(() => toast.error("切换失败"));
  };

  return (
    <ShuttleSectionWrapper>
      <ShuttleSectionItem
        chainPng={StaticData[ethOrBsc].chainImage}
        chainName={StaticData[ethOrBsc].chainName}
        coinPng={StaticData[ethOrBsc].coinImage}
        coinName={StaticData[ethOrBsc].coinName}
        balance={ethOrBsc === "eth" ? ethDaoBalance : bscDaoBalance}
        setShuttleValue={setShuttleValue}
      />
      <ExchangeButton
        onClick={() => {
          const value = ethOrBsc === "eth" ? "bsc" : "eth";
          switchChain(value);
          setEthOrBsc(value, () => updateDaoBalance());
          setShuttleValue(0);
        }}
      >
        <ExchangeIcon src="./assets/icon/exchange.png" />
      </ExchangeButton>
      <ShuttleSectionItem
        chainPng={StaticData[ethOrBsc === "eth" ? "bsc" : "eth"].chainImage}
        chainName={StaticData[ethOrBsc === "eth" ? "bsc" : "eth"].chainName}
        coinPng={StaticData[ethOrBsc === "eth" ? "bsc" : "eth"].coinImage}
        coinName={StaticData[ethOrBsc === "eth" ? "bsc" : "eth"].coinName}
        balance={ethOrBsc === "eth" ? bscDaoBalance : ethDaoBalance}
        inputValue={shuttleValue}
        inputDisabled
      />
      <ShuttleSectionButton
        disabled={
          isShuttle ||
          (daoAllowance &&
            (Number(shuttleValue) === 0 ||
              Number(shuttleValue) >
              Number(ethOrBsc === "eth" ? ethDaoBalance : bscDaoBalance)))
        }
        error={address !== "" && netError}
        title={
          address === ""
            ? "连接钱包"
            : netError
              ? `网络错误,切换到${ethOrBsc.toUpperCase()}`
              : buttonTitle
        }
        onClick={async () => {
          if (address === "") {
            onConnect(address, dispatch);
          } else if (netError) {
            switchChain(ethOrBsc);
          } else {
            if (daoAllowance) {
              onDepositDao();
            } else {
              onDaoApproval();
            }
          }
        }}
      />
    </ShuttleSectionWrapper>
  );
};

export default ShuttleSection;
