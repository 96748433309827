import Web3 from "web3";
import BridgeApi from "./abis/bridge.json";
import DaoApi from "./abis/dao.json";
export const Ethereum = window.ethereum;

export const initWeb3 = (provider) => {
  const web3 = new Web3(provider);
  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });
  return web3;
};

export const getChainId = async () => {
  const chainId = await Ethereum.request({ method: "eth_chainId" });
  return chainId;
};

export const InitContract = (
  abiJson,
  contractAddress,
  address,
  provider,
  view = false
) => {
  const web3 = initWeb3(
    view ? provider ?? Web3.givenProvider : Web3.givenProvider
  );
  const contract = new web3.eth.Contract(abiJson, contractAddress, { address });
  return contract;
};
/**
 * @returns BscDao合约
 */
export const DaoBscContract = (address, view = false) => {
  return InitContract(
    DaoApi,
    process.env.REACT_APP_BSC_DAO_ADDRESS,
    address,
    process.env.REACT_APP_BSC_RPC_URL,
    view
  );
};
/**
 * @returns BscBridge合约
 */
export const BridgeBscContract = (address, view = false) => {
  return InitContract(
    BridgeApi,
    process.env.REACT_APP_BSC_BRIDGE_ADDRESS,
    address,
    process.env.REACT_APP_BSC_RPC_URL,
    view
  );
};
/**
 * @returns EthDao合约
 */
export const DaoEthContract = (address, view = false) => {
  return InitContract(
    DaoApi,
    process.env.REACT_APP_ETH_DAO_ADDRESS,
    address,
    process.env.REACT_APP_ETH_RPC_URL,
    view
  );
};
/**
 * @returns EthBridge合约
 */
export const BridgeEthContract = (address, view = false) => {
  return InitContract(
    BridgeApi,
    process.env.REACT_APP_ETH_BRIDGE_ADDRESS,
    address,
    process.env.REACT_APP_ETH_RPC_URL,
    view
  );
};
