import styled from "styled-components";

const SectionWrapper = styled.section`
  width: 90%;
  height: 80vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 600px) {
    min-height: 100vh;
    height: 100%;
    margin-bottom: 20vh;
  }
`;

const TitleSpan = styled.span`
  font-size: 3rem;
  font-weight: bold;
  margin-top: 2rem;
  position: relative;
  z-index: 3;
  @media (max-width: 600px) {
    font-size: 1.7rem;
    max-width: 90%;
    text-align: center;
  }
`;

const ContentSpan = styled.span`
  max-width: 80%;
  text-align: justify;
  text-align-last: center;
  margin-top: 3rem;
  line-height: 2rem;
  font-weight: 500;
  position: relative;
  z-index: 3;
  @media (max-width: 600px) {
    line-height: 1.5rem;
    max-width: 90%;
  }
`;

const ApeImage = styled.img`
  width: 25rem;
  position: absolute;
  bottom: 10%;
  left: 10%;
  z-index: 0;
  opacity: 1;
  @media (max-width: 600px) {
    width: 20rem;
    bottom: -10%;
  }
`;

const RobotImage = styled.img`
  width: 15rem;
  position: absolute;
  top: 10%;
  right: 10%;
  z-index: 0;
  opacity: 1;
  opacity: .8;
  @media (max-width: 600px) {
    width: 10rem;
    top: 0%;
    right: auto;
    left: 0;
  }
`;

export default function StoryBackgroundSection() {
  return (
    <SectionWrapper>
      <TitleSpan>The story of the colonists on Mars</TitleSpan>
      <ContentSpan>
        In 2065, human beings began to migrate to Mars on a large scale, and
        human beings entered the era of interstellar economy. <br />
        Therefore, early entry into the Martian city, land development,
        agriculture and business became the largest capital fast lane. <br />
        A large number of businessmen and practitioners began to book
        interstellar ships Tickets to the New World to live. However, <br />
        the tickets to the New World are so rare that the few spacecraft in the
        world, even if they carry passengers every day, <br />
        can only transport a few passengers to Mars. Most people eventually Will
        stay on the earth forever. <br />
        So human beings began to study and explore the use of virtual reality
        equipment to remotely control robots or other species on Mars, <br />
        so as to carry out interstellar migration spiritually and ideologically.
        The birth of the new world and the transformation of the old world ,
        <br />
        gradually formed a sharp contrast. But war and plundering will always be
        the theme of human survival. <br />
        The struggle for the right to develop resources on Mars, the replacement
        of the dominance of Earth's species, <br />
        and the convergence of the old and new worlds in the next few hundred
        years, for the benefit of mankind. Destiny opens a new chapter.
      </ContentSpan>
      <img
        style={{
          width: "40rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 0,
          transform: "translate(-50%, -50%)",
          opacity: ".5",
        }}
        src="./assets/robot/rocket.png"
      />
      <ApeImage src="./images/ape.png" />
      <RobotImage src="./images/robot1.png" />
    </SectionWrapper>
  );
}
