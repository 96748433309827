import styled from "styled-components";

const ShuttleRecordItemWrapper = styled.section`
  max-width: 60rem;
  width: 100%;
  padding: 0.6rem;
  border-radius: 0.3rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  align-items: center;
  color: var(--shuttle-section-input-color);
  font-size: 14px;
  margin: 0.5rem 0;
  user-select: none;
  @media (max-width: 500px) {
    width: 90%;
    font-size: .7rem;
  }
`;

const ShuttleRecordItemHeader = () => {
  return (
    <ShuttleRecordItemWrapper>
      <span>Hash</span>
      <span>From</span>
      <span>To</span>
      <span>Status</span>
      <span>Asset</span>
      <span>Amount</span>
    </ShuttleRecordItemWrapper>
  );
};

export default ShuttleRecordItemHeader;
