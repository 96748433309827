import styled, { css } from "styled-components";

const datas = [
  {
    title: "Adventure",
    content: "Martian Ruins Adventure, Get game rewards by fighting monsters",
  },
  {
    title: "PK",
    content: "Get game rewards through duels between heroes",
  },
  {
    title: "Team Fight",
    content: "Through the confrontation between guilds, get game rewards",
  },
  {
    title: "Camp",
    content:
      "The confrontation between the two major race camps of robots and Martian apes for control of the Martian city",
  },
];
const SectionWrapper = styled.section`
  width: 90%;
  height: 80vh;
  position: relative;
  @media (max-width: 600px) {
    min-height: 100vh;
    height: 100%;
    margin-bottom: 20vh;
  }
`;
const StrategyItemWrapper = styled.section`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  margin-bottom: 10vh;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
`;
const StrategyItem = styled.section`
  display: flex;
  flex-direction: column;
  border: solid #fff 2px;
  width: 11rem;
  height: 20rem;
  border-radius: 0.5rem;
  align-items: center;
  padding: 2rem;
  text-align: center;
  @media(max-width: 600px) {
    width: 60%;
    height: auto;
  }
`;
export default function StrategySection() {
  return (
    <SectionWrapper>
      <span
        style={{
          display: "block",
          fontSize: "3rem",
          fontWeight: "bold",
          lineHeight: "7rem",
          textAlign: "center",
          width: "100%",
        }}
      >
        Strategy
      </span>
      <div class="h_iframe">
        <iframe
          src="https://www.youtube.com/embed/_oEgAktwCbU"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <StrategyItemWrapper>
        {datas.map((data, index) => {
          return (
            <StrategyItem key={index}>
              <img
                src={`./images/s${index + 1}.jpg`}
                alt="1"
                style={{ width: "12rem", borderRadius: ".5rem" }}
              />
              <span
                style={{
                  color: "#E46035",
                  fontSize: "2rem",
                  fontWeight: "500",
                  margin: ".5rem",
                }}
              >
                {data.title}
              </span>
              <span style={{ fontSize: ".9rem", lineHeight: "1.3rem" }}>
                {data.content}
              </span>
            </StrategyItem>
          );
        })}
      </StrategyItemWrapper>
    </SectionWrapper>
  );
}
