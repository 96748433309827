import styled from "styled-components";

const SectionWrapper = styled.section`
  width: 90%;
  height: 80vh;
  position: relative;
  @media (max-width: 600px) {
    height: 90vh;
  }
`;

const MapImg = styled.img`
  position: absolute;
  width: 92%;
  height: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  z-index: 0;
  @media (max-width: 600px) {
    width: 400%;
    height: 90%;
  }
`;

const LocationImg = styled.img`
  position: fixed;
  width: 2rem;
  bottom: ${(props) => props.location.x}%;
  left: ${(props) => props.location.y}%;
  cursor: pointer;
`;

const ModalSection = styled.section`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  left: 26.4%;
  bottom: 22%;
  align-items: center;
  @media (max-width: 600px) {
    left: 2%;
    bottom: 21%;
    transform: scale(.8);
  }
`;
const locations = [
  {
    x: 30,
    y: 50,
  },
  {
    x: 20,
    y: 20,
  },
  {
    x: 50,
    y: 40,
  },
  {
    x: 25,
    y: 80,
  },
  {
    x: 50,
    y: 70,
  },
  {
    x: 20,
    y: 40,
  },
];
export default function CitySection() {
  return (
    <SectionWrapper>
      <MapImg src="./images/marsmap.png" />
      <ModalSection>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#1C1B22",
            borderRadius: ".5rem",
            padding: ".4rem",
            width: "18rem",
          }}
        >
          <img src="./images/car.png" style={{ width: "100%" }} />
          <span style={{ fontSize: ".8rem", padding: ".6rem" }}>
            Own your land in a martian city
            <br />
            The land can produce various mineral resources, which can be exchanged for the Mars Economic Token $HELLO
          </span>
        </section>
        <img
          src="./assets/icon/down.png"
          style={{ width: "1rem", marginTop: "-.3rem" }}
        />
      </ModalSection>
      {locations.map((location, index) => {
        return (
          <LocationImg
            key={index}
            src="./assets/icon/location.png"
            location={location}
          />
        );
      })}
    </SectionWrapper>
  );
}
