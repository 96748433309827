import { useState } from "react";
import styled, { css } from "styled-components";
import CitySection from "./CitySection";
import MarsSection from "./MarsSection";
import RoadMapSection from "./RoadMapSection";
import StoryBackgroundSection from "./StoryBackgroundSection";
import StrategySection from "./StrategySection";

const HomeWrapper = styled.section`
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    ${(props) => props.color[0]},
    ${(props) => props.color[1]}
  );
  box-sizing: border-box;
  padding: 3rem;
  color: #fff;
  overflow-x: hidden;
  border-left: solid 1px #fff;
  border-right: solid 1px #fff;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 0;
    border: none;
  }
`;
const colors = [
  ["#222531", "#0C092F"],
  ["#0C092F", "#1A1510"],
  ["#1A1510", "#09124F"],
  ["#09124F", "#1B6295"],
  ["#1B6295", "#9986E3"],
];

const Home = () => {
  const [menuCurrent, setMenuCurrent] = useState(0);

  return (
    <HomeWrapper color={colors[menuCurrent]}>
      <Header color={colors[menuCurrent]}>
        <section style={{ display: "flex" }}>
          <img
            src="./assets/icon/logo.png"
            alt="logo"
            style={{ width: "2rem" }}
          />
          <span
            style={{
              marginLeft: "1rem",
              fontWeight: "bold",
              fontSize: ".9rem",
              letterSpacing: ".4rem",
              marginLeft: "1rem",
            }}
          >
            HELLO
            <br />
            DAO
          </span>
        </section>
        <section
          style={{
            display: "grid",
            gap: ".4rem",
            gridTemplateColumns: "repeat(4, 2.5rem)",
          }}
        >
          <a href="https://t.me/+sSzAmJ0kD9M4MWRl" target="_blank">
            <img
              src="./assets/icon/telegram.png"
              alt="telegram"
              style={{ width: "2rem" }}
            />
          </a>
          <a href="https://twitter.com/hellodaoworld" target="_blank">
            <img
              src="./assets/icon/twitter.png"
              alt="discord"
              style={{ width: "2rem" }}
            />
          </a>
          <a href="https://discord.gg/NEbmfG8eTB" target="_blank">
            <img
              src="./assets/icon/discord.png"
              alt="discord"
              style={{ width: "2rem" }}
            />
          </a>
          <a href="https://community.hellodao.world/" target="_blank">
            <img
              src="./assets/icon/gitbook.png"
              alt="gitbook"
              style={{ width: "2rem" }}
            />
          </a>
        </section>
      </Header>
      {menuCurrent === 0 ? (
        <MarsSection />
      ) : menuCurrent === 1 ? (
        <StoryBackgroundSection />
      ) : menuCurrent === 2 ? (
        <StrategySection />
      ) : menuCurrent === 3 ? (
        <CitySection />
      ) : (
        <RoadMapSection />
      )}
      <section>
        {menuCurrent > 0 ? (
          <LastSection onClick={() => setMenuCurrent(menuCurrent - 1)}>
            <PageImg src="./assets/icon/last2.png" alt="last" />
          </LastSection>
        ) : (
          ""
        )}
        {menuCurrent < 4 ? (
          <NextSection onClick={() => setMenuCurrent(menuCurrent + 1)}>
            <PageImg src="./assets/icon/next2.png" alt="last" />
          </NextSection>
        ) : (
          ""
        )}
      </section>
      <BottomMenuWrapper>
        {["Target", "Background", "How to play", "Mars City", "Road Map"].map(
          (title, index) => {
            return (
              <BottomMenu
                active={menuCurrent === index}
                key={title}
                onClick={() => setMenuCurrent(index)}
              >
                {index + 1}.{title}
              </BottomMenu>
            );
          }
        )}
      </BottomMenuWrapper>
    </HomeWrapper>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8rem;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 1rem .5rem;
    width: 90%;
  }
`;
const PageButton = styled.section`
  border: solid #fff 1px;
  padding: 1rem;
  cursor: pointer;
  position: fixed;
  z-index: 999;
  @media (max-width: 600px) {
    padding: 0.4rem;
  }
`;
const PageImg = styled.img`
  width: 2rem;
  @media (max-width: 600px) {
    width: 1.5rem;
  }
`;
const LastSection = styled(PageButton)`
  top: 50%;
  left: 0;
  border-top-right-radius: 99rem;
  border-bottom-right-radius: 99rem;
`;
const NextSection = styled(PageButton)`
  top: 50%;
  right: 0;
  border-top-left-radius: 99rem;
  border-bottom-left-radius: 99rem;
`;

const BottomMenuWrapper = styled.footer`
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(5, 13rem);
  align-items: flex-end;
  z-index: 999;
  @media (max-width: 600px) {
    width: 100vw;
    position: fixed;
    grid-template-columns: 15vw 25vw 20vw 14vw 26vw;
  }
`;

const BottomMenu = styled.section`
  color: #0b1234;
  letter-spacing: 0.2rem;
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0 1rem;
  background-color: #cfcdeb;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s;
  user-select: none;
  ${(props) =>
    props.active
      ? css`
          border-top: solid 4px #E46035;
          box-shadow: 3px 0 3px rgba(0,0,0,.4), -3px 0 3px rgba(0,0,0,.4),
          height: 50px;
          background-color: #FFF;
        `
      : ""}
  @media(max-width: 600px) {
    letter-spacing: 0rem;
    font-size: 0.6rem;
    height: 55px;
  }
`;

export default Home;
