import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./pages/App.jsx";
import { HashRouter } from "react-router-dom";
import { MetamaskStateProvider } from "use-metamask";

ReactDOM.render(
  <React.StrictMode>
    <MetamaskStateProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </MetamaskStateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
